import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
// import SolidButton from '../Button/SolidButton/index';

//? STYLED COMPONENTS
import styled from 'styled-components';

const OuterContainer = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	@media screen and (max-width: 768px) {
		padding-right: 0;
	}
`;

const InnerContainer = styled.div`
	position: relative;
	display: inline-flex;
	transition: all 0.4s ease-in-out;
	border-radius: 1.2rem;	
	background: linear-gradient(
		90deg,
		${({ background }) => background.primary} 10%,
		${({ background }) => background.secondary} 100%
	);

	@media screen and (max-width: 640px) {
		background: linear-gradient(
			180deg,
			${({ background }) => background.primary} 10%,
			${({ background }) => background.secondary} 100%
		);
	}

	&:hover {
		transform: scale(1.015);
		/* box-shadow: ${({ background }) => `${background.primary}44`} 0px 18px 50px -10px; */
		/* box-shadow: ${({ background }) => `${background.primary}11`} 0px 4px 16px, ${({ background }) =>
			`${background.primary}55`} 0px 8px 32px; */
		box-shadow: ${({ background }) => `${background.primary}77`} -4px 9px 25px -6px;
	}
`;

const Tag = styled.div`
	background: ${({ background }) => background};
`;

const Project = ({ id, title, descr, logo, pallete, img, orientation, link }) => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<OuterContainer className='relative single-project'>
			<NavLink exact to={link}>
				<InnerContainer background={pallete} className=''>
					<div className='w-full h-full xl:h-project flex flex-col sm:flex-row p-6 py-8 xl:p-16'>
						<div className='flex-1 flex-grow flex flex-col wow animate__animated animate__fadeInUp'>
							<img src={logo} alt={`${title} logo`} className='w-5/6 xl:w-full' />
							<p className='paragraph-text text-white font-light tracking-wide my-4 xl:my-8'>{descr}</p>
							<div className='hidden sm:flex w-full my-4 xl:my-6'>
								<Tag background={pallete.tag} className='label-tags'>website</Tag>
								<Tag background={pallete.tag} className='label-tags'>marketing</Tag>
							</div>
						</div>
						<div className='flex-1 flex-grow flex flex-col relative sm:-right-20 md:-right-16 lg:-right-24 xl:-right-28'>
							<Parallax className='m-auto py-8 sm:py-0' y={[-5, 5]}>
								<img
									src={img}
									alt={`${title} project`}
									className='transform scale-130 sm:scale-110 md:scale-130 lg:scale-100 xl:scale-120 wow animate__animated animate__fadeIn'
									data-wow-duration='1.5s'
									data-wow-delay='0.3s'
								/>
							</Parallax>
							<div className='flex sm:hidden w-full mt-4 xl:my-6'>
								<Tag background={pallete.tag} className='label-tags'>website</Tag>
								<Tag background={pallete.tag} className='label-tags'>marketing</Tag>
							</div>
						</div>
					</div>
				</InnerContainer>
			</NavLink>
		</OuterContainer>
	);
};

export default Project;
