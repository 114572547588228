import React from "react";

const Logo = ({type, usedForNav = true, extraClass}) => {
	return (
		<div className={`text-4xl md:${usedForNav ? 'text-4xl' : 'text-7xl'} flex font-black font-montserrat ${!type ? "text-tad-black" : "text-white"}`}>
			<img loading="lazy" src='/resources/img/tad-logo-center.png' alt='TAD icon' className={`w-10 h-10 mr-2 class ${extraClass}`} />
			tad <span className='text-tad-primary'>.</span>
		</div>
	);
};

export default Logo;
