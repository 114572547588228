import React from 'react';

//? STYLED COMPONENTS
import styled from 'styled-components';

const Article = styled.div`
	position: relative;
	width: 48.5%;
	height: auto;

	background: ${({ background }) => background.primary};
	background: linear-gradient(
		45deg,
		${({ background }) => background.primary} 30%,
		${({ background }) => background.secondary} 100%
	);

	@media screen and (min-width: 768px) and (max-width: 1500px) {
		/* height: 40rem; */
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		height: auto;
	}
`;

const Tag = styled.div`
	background: ${({ background }) => background};
`;

const Img = styled.img`
	filter: invert(1);
`;

const TopService = ({ id, heading, text, tags, pallete, icon }) => {
	return (
		<Article background={pallete} className='mb-8 md:mb-7 p-10 flex flex-col rounded-2xl about-service'>
			<Img src={icon} alt='' className='w-16 h-auto text-white' />
			<h1
				className='text-2xl lg:text-3xl text-white font-inter font-bold my-4 lg:my-12 capitalize wow animate__animated animate__fadeInUp'
				data-wow-delay='0.3s'
			>
				{heading}
			</h1>
			<p
				className='paragraph-text text-white font-inter leading-loose wow animate__animated animate__fadeInUp'
				data-wow-delay='0.5s'
			>
				{text}
			</p>
			<div
				className='flex flex-row flex-wrap mt-6 lg:mt-14 wow animate__animated animate__fadeInUp'
				data-wow-delay='0.7s'
			>
				{tags.map((tag, index) => {
					return (
						<Tag key={index} background={pallete.tag} className='label-tags'>
							{tag}
						</Tag>
					);
				})}
			</div>
		</Article>
	);
};

export default TopService;
