import React from 'react';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? COMPONENT
import Logo from '../Logo';

//? REACT ICONS
import {
	FiLinkedin,
	FiInstagram,
	FiFacebook,
	FiDribbble,
} from 'react-icons/fi';

const Section = styled.div`
	&.dark {
		background: rgba(60, 60, 59, 0.97);
		/* background: linear-gradient(
			0deg,
			rgba(60, 60, 59, 1) 0%,
			rgba(68, 68, 66, 1) 90%
		); */

		.dark-shade {
			background: rgb(60, 60, 59);
		}
	}
`;

const Footer = ({ type, logoType, logoClass, usedForNav, children }) => {
	return (
		<Section className={!type ? 'text-tad-black' : 'dark text-white'}>
			{children ?? children}

			<div className={`py-10 md:py-14 flex-col justify-between ${!type ? 'w-full bg-gray-100' : 'dark-shade'}`}>
				<div className='container w-full md:px-24 flex flex-wrap md:flex-row'>
					<div className='flex flex-col w-full mb-5 md:w-1/4'>
						<Logo
							type={logoType}
							extraClass={logoClass}
							usedForNav={usedForNav}
						/>
					</div>
					<div className='flex flex-col w-1/2 md:w-1/4'>
						<h3 className='footer-heading'>
							services
						</h3>

						<p className='footer-links'>
							design
						</p>
						<p className='footer-links'>
							development
						</p>
						<p className='footer-links'>
							marketing
						</p>
						<p className='footer-links'>
							networking
						</p>
					</div>
					<div className='flex flex-col w-1/2 md:w-1/4'>
						<h3 className='text-xl font-inter font-medium capitalize mb-2 md:mb-5'>
							company
						</h3>

						<p className='footer-links'>
							portfolio
						</p>
						<p className='footer-links'>
							products
						</p>
						<p className='footer-links'>
							about
						</p>
						<p className='footer-links'>
							contact us
						</p>
					</div>
					<div className='hidden md:flex flex-col w-1/2 md:w-1/4'>
						<h3 className='text-xl font-inter font-medium capitalize mb-2 md:mb-5'>
							follow us
						</h3>

						<p className='footer-links'>
							dribbble
						</p>
						<p className='footer-links'>
							instagram
						</p>
						<p className='footer-links'>
							facebook
						</p>
						<p className='footer-links'>
							linkedIn
						</p>
					</div>
				</div>

				<div className='container pt-0 md:pt-16 md:px-24 w-full font-light flex flex-col md:flex-row justify-center md:justify-between'>
					<div className='w-full flex justify-center md:hidden py-8'>
						<div className='w-2/6 m-auto flex flex-row justify-around'>
							<p>
								<FiDribbble />
							</p>
							<p>
								<FiInstagram />
							</p>
							<p>
								<FiFacebook />
							</p>
							<p>
								<FiLinkedin />
							</p>
						</div>
					</div>

					<div className='flex w-full justify-center md:justify-start'>
						<p className="text-sm font-inter font-light">
							Copyright © {new Date().getFullYear()}{' '}
							<span className='font-semibold'>tad</span>. All rights reserved.
						</p>
					</div>
					<div className='flex w-full justify-center md:justify-end'>
						<p className='cursor-pointer hover:underline w-max mx-2 text-sm font-inter font-light'>Cookie Policy</p>
						<p className='cursor-pointer hover:underline w-max mx-2 text-sm font-inter font-light'>Privacy Policy</p>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Footer;
