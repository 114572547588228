import React from 'react';

const ScrollIndicator = () => {
	return (
		<div className='h-full fixed inset-0 z-30 hidden sm:flex flex-col justify-end pointer-events-none'>
			<div className='absolute -left-50 bottom-28 -right-5 transform rotate-90 flex'>
				<div className='w-20 flex justify-center items-center border-b border-tad-black  top-0 transform -translate-y-1/2'></div>
				<p className='relative ml-4 text-black font-thin font-inter uppercase'>
					scroll
				</p>
			</div>
		</div>
	);
};

export default ScrollIndicator;
