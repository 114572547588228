import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
import SolidButton from '../components/Button/SolidButton';
import OutlineButton from '../components/Button/OutlineButton';
import Process from '../components/Process';
import Footer from '../components/Footer';
import SingleService from '../components/Service/Top';
import SingleClient from '../components/Client';
import StaffCard from '../components/Staff';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? REACT ICONS
import { FiPhone } from 'react-icons/fi';

//? PARALLAX
import { Parallax } from 'react-scroll-parallax';

//? SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const Section = styled.div`
	height: auto;
	background: rgb(60, 60, 59);
	background: linear-gradient(-180deg, rgba(60, 60, 59, 0.8547619731486344) 0%, rgba(60, 60, 59, 1) 65%);
`;

const JobCard = styled.div`
	width: 21rem;
	height: 21rem;

	@media screen and (min-width: 769px) and (max-width: 1300px) {
		width: 20rem;
		height: 20rem;
	}

	@media screen and (max-width: 768px) {
		width: 15rem;
		height: 15rem;
	}
`;

const MotivatedTeamContainer = styled.div`
	height: 862px;

	@media screen and (min-width: 1920px) {
		height: 900px;
	}

	@media screen and (min-width: 769px) and (max-width: 1300px) {
		height: 700px;
	}

	@media screen and (max-width: 768px) {
		height: 550px;
	}
`;

const processes = [
	{
		id: 1,
		heading: 'Discovery & Strategy',
		text: 'We digest intel on your users, research your industry, take stock of the competition, and devise a plan.',
		img: '/resources/img/about-process-1.png',
	},
	{
		id: 2,
		heading: 'Wireframes & Flows',
		text: 'Setting up an intuitive user experience, navigation, and content hierarchy to start off on the right foot.',
		img: '/resources/img/about-process-2.png',
	},
	{
		id: 3,
		heading: 'Design & Visuals',
		text: 'We convert wireframes into immersive hi-fi screens, leveraging visual design and brand language to create an engaging experience.',
		img: '/resources/img/about-process-3.png',
	},
	{
		id: 4,
		heading: 'Development & Results',
		text: 'Time for finalized screens infused with personality and life. We also create a UI kit to allow you to easily build your future.',
		img: '/resources/img/about-process-4.png',
	},
];

const services = [
	{
		id: 1,
		heading: 'Design',
		icon: '/resources/img/design-icon.png',
		text: 'Elegant graphic design that speaks your brand’s language and delivers on your goals. Professional visuals to communicate messages. From logos, banners to flyers, we do it all.',
		tags: ['logos', 'flyers', 'banners'],
		pallete: {
			primary: '#18A0FB',
			secondary: '#B0DFFF',
			tag: '#87CEFF',
		},
	},
	{
		id: 2,
		heading: 'Development',
		icon: '/resources/img/dev-icon.png',
		text: 'We are experts at making websites and apps that are easy to use, beautiful and engaging. A CMS to make content changes easier. From websites, web, mobile to desktop apps, we do it all.',
		tags: ['websites', 'web apps', 'desktop apps', 'mobile apps'],
		pallete: {
			primary: '#8B37FF',
			secondary: '#DAB9FF',
			tag: '#DAB9FF',
		},
	},
	{
		id: 3,
		heading: 'Marketing',
		icon: '/resources/img/marketing-icon.png',
		text: 'Whether you’re a startup or multinational, we’ll help you develop or extend a recognizable brand that connects with your audiences. From Facebook, Google to Snapchat Ads, we do it all.',
		tags: ['facebook ads', 'google ads', 'snapchat ads'],
		pallete: {
			primary: '#FF7138',
			secondary: '#FFCFB8',
			tag: '#FFB999',
		},
	},
	{
		id: 4,
		heading: 'Networking',
		icon: '/resources/img/network-icon.png',
		text: 'We help setup networks to deliver information to employees, suppliers, and customers. By making it easy and fast to share information. From network, infrastructure to servers, we do it all.',
		tags: ['networks', 'infrastructures', 'servers'],
		pallete: {
			primary: '#FF4849',
			secondary: '#FFB8B8',
			tag: '#FF9393',
		},
	},
];

const clients = [
	{
		id: 1,
		logo: '/resources/img/client-smart-logo.png',
		pallete: {
			primary: '#1EB6EC',
			secondary: '#9BE2FE',
		},
	},
	{
		id: 2,
		logo: '/resources/img/client-slimbouw-logo.png',
		pallete: {
			primary: '#EE3342',
			secondary: '#FF9EA6',
		},
	},
	{
		id: 3,
		logo: '/resources/img/client-ride-logo.png',
		pallete: {
			primary: '#000000',
			secondary: '#00000050',
		},
	},
	{
		id: 4,
		logo: '/resources/img/client-panam-logo.png',
		pallete: {
			primary: '#273348',
			secondary: '#5A6D8F',
		},
	},
	{
		id: 5,
		logo: '/resources/img/client-lia-logo.png',
		pallete: {
			primary: '#282828',
			secondary: '#686868',
		},
	},
	{
		id: 6,
		logo: '/resources/img/client-cmc-logo.png',
		pallete: {
			primary: '#08498C',
			secondary: '#94B9E0',
		},
	},
];

const staffs = [
	{
		id: 1,
		name: 'Akash Prahladsingh',
		position: 'Co-founder / CEO',
		img: null,
	},
	{
		id: 2,
		name: 'Kedeem Vers',
		position: 'Co-founder / CTO',
		img: null,
	},
	{
		id: 3,
		name: 'Rick Asmodikromo',
		position: 'Co-founder / COO',
		img: null,
	},
];

const About = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			<section className='h-auto xl:h-hero hero-section overflow-x-hidden'>
				<div className='container relative flex flex-col-reverse md:flex-row h-full md:px-24 overflow-x-visible'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-center py-5 xl:pr-16'>
						<h1
							className='text-4xl md:text-5xl font-inter text-tad-primary font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							We design
						</h1>
						<h1
							className='text-5xl md:text-7xl font-inter text-tad-black font-semibold mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							digital products
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							We are a remote digital agency based in Suriname, working with clients around the world. We love building
							products that are easy to use, accessible, engaging, and delightful.
						</p>
					</div>
					<div className='relative w-full md:w-1/2 flex flex-col items-start justify-center py-0'>
						<picture
							className='relative w-full h-auto inline md:absolute transform scale-107 md:scale-130 xl:scale-350 md:-right-3/4 xl:pl-20 wow animate__animated animate__fadeIn'
							data-wow-duration='1.5s'
						>
							<source media='(min-width: 768px)' srcSet='/resources/img/about-hero-banner.png' />
							<img
								loading='lazy'
								src='/resources/img/about-hero-banner-mobile.png'
								alt=''
							/>
						</picture>
					</div>
				</div>
			</section>

			<section>
				<div className='container flex flex-col md:flex-row h-full py-5 sm:py-14 xl:px-20 xl:py-32'>
					<div className='w-full md:1/2 flex flex-col items-start justify-center'>
						<img
							loading='lazy'
							src='/resources/img/tad-logo-center.png'
							alt='tad icon'
							className='wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						/>
					</div>
					<div className='w-full md:1/2 flex flex-col items-start justify-center md:px-14'>
						<h1
							className='text-4xl lg:text-5xl text-tad-primary font-inter font-light capitalize wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							our mission
						</h1>
						<h1
							className='text-4xl lg:text-6xl text-tad-black font-inter font-bold capitalize mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							out of the box
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							Thinking out side of the box gets us out of bed every morning. Through thoughtful design, we create
							delightful digital experiences that make life simpler and more enjoyable. Our mission is to centralize all
							technology work available on the market and internationally. Whether its designing logos, banners and
							flyers. Building websites, web, mobile and desktop apps. Running Facebook ads, Google ads and Snapchat
							ads. Setting up networks, infrastructures and servers.
						</p>
					</div>
				</div>
			</section>

			<section>
				<div className='my-6 md:my-0'>
					<img loading='lazy' src='/resources/img/tad-group.jpg' alt='about us group' className='w-full' />
				</div>
			</section>

			<section>
				<div className='container flex flex-col-reverse md:flex-row py-5 pb-0 md:px-24 md:py-20 md:pb-0'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'>
						<h1
							className='text-4xl lg:text-5xl text-tad-primary font-inter font-light capitalize wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							our process
						</h1>
						<h1
							className='text-4xl lg:text-6xl text-tad-black font-inter font-bold capitalize mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							building products
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							We integrate our experience with our clients` ideas which transform affordable solutions. We can handle
							your entire software development cycle for the best customer experience.
						</p>
					</div>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'></div>
				</div>
				<div className='w-full relative overflow-hidden'>
					<div className='container md:px-24 overflow-x-visible '>
						<div className='building-process-slider my-14 xl:my-20'>
							<Swiper
								spaceBetween={30}
								slidesPerView={'auto'}
								onSwiper={null}
								centeredSlides={false}
							>
								{processes.map((process, index) => {
									return (
										<SwiperSlide key={index}>
											<Process counter={process.id} header={process.heading} text={process.text} img={process.img} />
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</div>
				</div>
			</section>

			<div className='section-border'>
				<hr />
			</div>

			<section>
				<div className='container flex flex-col-reverse md:flex-row py-10 md:px-24 md:py-20'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'>
						<h1
							className='text-4xl lg:text-5xl text-tad-primary font-inter font-light capitalize wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							services
						</h1>
						<h1
							className='text-4xl lg:text-6xl text-tad-black font-inter font-bold capitalize mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							what do we do
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							We help companies transform their organization into progressive growth for their future.{' '}
							<span className='font-bold'>tad</span>
							<span className='text-tad-primary font-bold'>.</span> designs, builds and creates the fundamental tools
							for success.
						</p>
					</div>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'></div>
				</div>
				<div className='w-full relative overflow-hidden'>
					<div className='container md:px-24 overflow-x-visible'>
						<div className='building-process-slider py-2 flex flex-row justify-between flex-wrap mb-14 xl:mb-20'>
							{services.map((service, index) => {
								return (
									<SingleService
										key={index}
										id={service.id}
										heading={service.heading}
										text={service.text}
										tags={service.tags}
										pallete={service.pallete}
										icon={service.icon}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</section>

			<div className='section-border'>
				<hr />
			</div>

			<section>
				<div className='container flex flex-col-reverse md:flex-row py-10 md:px-24 md:py-20'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'>
						<h1
							className='text-4xl lg:text-5xl text-tad-primary font-inter font-light capitalize wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							Trusted by
						</h1>
						<h1
							className='text-4xl lg:text-6xl text-tad-black font-inter font-bold capitalize mt-3 md:mt-4 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							awesome clients
						</h1>
					</div>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'></div>
				</div>
				<div className='container flex flex-col-reverse md:flex-row md:px-24 pb-10 md:pb-20'>
					<div className='w-full flex flex-row flex-wrap'>
						{clients.map((client, index) => {
							return <SingleClient key={index} id={client.id} pallete={client.pallete} img={client.logo} />;
						})}
					</div>
				</div>
			</section>

			<Section className='w-full relative overflow-hidden'>
				<div className='absolute top-0 right-0 z-0 overflow-hidden h-full'>
					<Parallax className='parallax-why-image' y={[-30, 30]}>
						<img
							loading='lazy'
							src='/resources/img/tad-logo-grey-faded.png'
							alt='TAD right corner icon'
							className='opacity-25'
						/>
					</Parallax>
				</div>

				<div className='container relative py-10 md:py-20 md:px-24 z-10'>
					<h1 className='text-2xl md:text-3xl text-white font-inter font-medium wow animate__animated animate__fadeInUp'>
						<span className='text-text-3xl md:text-4xl font-montserrat font-black'>tad</span>
						<span className='text-text-3xl md:text-4xl text-tad-primary font-montserrat font-black'>.</span> leadership
					</h1>
				</div>

				<div className='container relative md:px-24 pb-10 md:pb-20 z-10'>
					<div className='w-full flex flex-row flex-wrap justify-around'>
						{staffs.map((staff, index) => {
							return (
								<StaffCard key={index} id={staff.id} name={staff.name} position={staff.position} img={staff.img} />
							);
						})}
					</div>
				</div>

				<MotivatedTeamContainer className='bg-white relative w-full overflow-hidden'>
					<div className='container md:px-24'>
						<div className='w-full lg:w-5/6 flex-col justify-center items-center m-auto'>
							<h3
								className='text-tad-primary text-3xl md:text-4xl text-center font-inter font-light capitalize pt-10 md:pt-20 wow animate__animated animate__fadeIn'
								data-wow-delay='0.3s'
								data-wow-duration='1.5s'
							>
								a team of
							</h3>
							<h1
								className='text-tad-black text-4xl md:text-6xl text-center font-inter font-bold capitalize pt-5 wow animate__animated animate__fadeIn'
								data-wow-delay='0.5s'
								data-wow-duration='1.5s'
							>
								Highly Motivated People
							</h1>
							<p
								className='paragraph-text text-tad-black text-center font-inter font-light pt-10 wow animate__animated animate__fadeIn'
								data-wow-delay='0.7s'
								data-wow-duration='1.5s'
							>
								Extraordinarily motivated people are driven to go above and beyond; they are trusted by others, focused
								and have great confidence in their own abilities. They dream of endless possibilities, and tend to be
								extremely satisfied with their lives.
							</p>
						</div>
						<img
							src='/resources/img/tad-group-transparent.png'
							alt=''
							className='absolute mx-auto inset-x-0 bottom-0 sm:-bottom-10 lg:-bottom-36'
						/>
					</div>
				</MotivatedTeamContainer>

				<div className='container py-10 md:py-32 md:px-24 overflow-x-visible services-slider about'>
					<Swiper
						spaceBetween={20}
						breakpoints={{
							768: {
								spaceBetween: 50,
							},
						}}
						loop={false}
						slidesPerView={'auto'}
						onSwiper={null}
						centeredSlides={false}
						className='flex items-center'
					>
						<SwiperSlide>
							<JobCard>
								<div className='w-full h-full flex flex-col justify-center items-start rounded-lg md:rounded-xl'>
									<h1
										className='w-full text-2xl md:text-4xl text-white font-inter font-light wow animate__animated animate__fadeInUp'
										data-wow-delay='0.3s'
									>
										Interested in joining
									</h1>
									<h1
										className='w-full text-3xl md:text-5xl text-white font-inter font-bold lg:my-5 wow animate__animated animate__fadeInUp'
										data-wow-delay='0.5s'
									>
										Our Team?
									</h1>
									<NavLink exact to='/jobs'>
										<SolidButton text={'start career'} extraClass={'bg-tad-primary mt-6 md:mt-14 text-white px-6'} />
									</NavLink>
								</div>
							</JobCard>
						</SwiperSlide>
						<SwiperSlide>
							<JobCard>
								<div className='overflow-hidden rounded-lg md:rounded-xl object-contain'>
									<img
										src='/resources/img/job-frontend.png'
										alt='tad job frontend'
										className='max-w-full max-h-full'
									/>
								</div>
							</JobCard>
						</SwiperSlide>
						<SwiperSlide>
							<JobCard>
								<div className='overflow-hidden rounded-lg md:rounded-xl'>
									<img
										src='/resources/img/job-backend.png'
										alt='tad job backend'
										className='max-w-full max-h-full'
									/>
								</div>
							</JobCard>
						</SwiperSlide>
					</Swiper>
				</div>
			</Section>

			<Footer logoClass='hidden' usedForNav={false}>
				<div className='lets-talk-section'>
					<h1 className='heading wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
						Want to work together?
					</h1>
					<h1 className='subheading wow animate__animated animate__fadeInUp' data-wow-delay='0.5s'>
						Let's talk.
					</h1>

					<div className='w-full flex flex-row justify-center items-center'>
						<NavLink to='/contact'>
							<SolidButton text={'start a project'} extraClass={'bg-tad-primary text-white px-6'} />
						</NavLink>
						<a href='tel:+5978925686'>
							<OutlineButton
								text={null}
								extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-7 sm:px-8'}
								outlineColor={'#24CE7B'}
							>
								<div className='text'>+597 892-5686</div>
								<FiPhone className='icon' />
							</OutlineButton>
						</a>
					</div>
				</div>
			</Footer>
		</>
	);
};

export default About;
