import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
import Logo from "../Logo/index";
import SolidButton from "../Button/SolidButton/index";

//? ICONS
import {ReactComponent as MenuIcon} from "../../images/svg/menu.svg";

const MenuButton = styled.button`
	border: 1px solid rgba(243, 244, 246);
	background: rgba(243, 244, 246);
	color: rgba(60, 60, 59);

	&.active {
		background-color: rgba(36, 206, 123);
		border: 1px solid rgba(36, 206, 123);
		color: rgb(255, 255, 255);
	}
`;

// const NavbarContainer = styled.div`
// 	width: 1400px;
// 	max-width: 100%;
// 	height: auto;

// 	@media screen and (max-width: 768px) {
// 		width: 100%;
// 	}
// `

const Navbar = ({toggleSidebar, isMenuOpen}) => {
	const [menuButton, setMenuButton] = useState(false);

	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, [])

	const changeMenuButtonBackground = () => {
		const navbar = document.querySelector(".navbar");

		if (window.scrollY >= navbar.clientHeight) {
			setMenuButton(true);
		} else {
			setMenuButton(false);
		}
	};

	window.addEventListener("scroll", changeMenuButtonBackground);

	return (
		<>
			<nav className='sticky top-0 z-40 pointer-events-none'>
				<div className='absolute inset-x-0 py-4 md:py-6 container mx-auto flex justify-end md:justify-between items-start wow animate__animated animate__fadeInDown' data-wow-duration="0.8s">
					<Link to='/' className='pointer-events-auto hidden md:flex'>
						<img loading="lazy" src='/resources/img/tad-logo-center.png' alt='TAD icon' className='w-10 h-10 md:w-12 md:h-12' />
					</Link>
					<div className='flex items-center'>
						<MenuButton className={`rounded-lg pointer-events-auto transition duration-200 ease-in-out ${menuButton && "active"}`} onClick={toggleSidebar}>
							<MenuIcon className={`open-menu-icon w-6-REMOVE transform rotate-180 ${isMenuOpen ? "hidden-remove inline" : "inline"}`} />
						</MenuButton>
					</div>
				</div>
			</nav>

			<div className='container navbar py-4 md:py-6 mx-auto relative md:px-16 xl:px-20 2xl:px-20 wow animate__animated animate__fadeInDown' data-wow-duration="0.8s">
				<div className='flex items-center justify-between'>
					<Link to='/'>
						<Logo extraClass='inline md:hidden' />
					</Link>

					<Link to='/contact'>
						<SolidButton text={"contact us"} extraClass={"hidden md:flex bg-tad-primary text-white"} />
					</Link>
				</div>
			</div>
		</>
	);
};

export default Navbar;
