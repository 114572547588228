import React from 'react';

//? COMPONENTS
import SingleProduct from './SingleProduct';

//? SWIPER
// import SwiperCore, {Navigation} from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

//? DATA
const products = [
	{
		id: 1,
		title: 'Flex Pos',
		description:
			'User-friendly Point of Sale software. Set-up in minutes, sell in seconds. Compatible with any device. No installation and no specific hardware required. Extremely flexible, you can configure Flex Pos to meet your precise needs.',
		image: '/resources/img/product-pos-dashboard.png',
		tags: ['pos', 'marketing'],
		pallete: {
			primary: '#ff146f',
			secondary: '#f876a8',
			background: '#FD84B2',
		},
		link: '/product-flexpos',
		position: 'left',
	},
	{
		id: 2,
		title: 'HR4U',
		description:
			"HR4U is a web based HR system created specially for small businesses that don't have dedicated IT staff. It comes with over 30 HR instruments, ranging from employee directory, leave requests and absence calendars.",
		image: '/resources/img/product-hr4u-dashboard.png',
		tags: ['hr system', 'marketing'],
		pallete: {
			primary: '#042F75',
			secondary: '#4487F3',
			background: '#7CA7EB',
		},
		link: '#',
		position: 'right',
	},
];

const Product = () => {
	return (
		<div className='products-slider'>
			<Swiper spaceBetween={0} slidesPerView={'auto'} onSwiper={null} centeredSlides={false}>
				{products.map((product, index) => {
					return (
						<SwiperSlide key={index}>
							<SingleProduct
								title={product.title}
								description={product.description}
								img={product.image}
								tags={product.tags}
								pallete={product.pallete}
								link={product.link}
								position={product.position}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Product;
