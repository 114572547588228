import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

//? COMPONENTS
import SolidButton from '../components/Button/SolidButton';
import OutlineButton from '../components/Button/OutlineButton';
import Footer from '../components/Footer';

//? REACT ICONS
import { FiArrowRight, FiArrowLeft, FiPhone } from 'react-icons/fi';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const GradientContainer = styled.div`
	max-height: 863px;
	height: 100%;
	width: 100%;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(30, 182, 236);
		background: linear-gradient(90deg, rgba(36, 206, 123, 1) 0%, rgba(119, 230, 147, 1) 100%);
		width: 100%;
		height: 70%;
		z-index: 9;

		@media screen and (max-width: 768px) {
			height: 70%;
		}
	}
`;

const JobDescriptionContainer = styled.div`
	display: none;
	transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-webkit-transition: all 1s ease-in-out;
	opacity: 0;

	&.active {
		display: flex;
		opacity: 1;
	}
`;

const JobNavigationButton = styled.button`
	background: #fff;
	border: 0;
	padding: 0.5rem 1.5rem;
	font-size: 1rem;
	font-weight: 600;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #24ce7b;

	&:hover {
		text-decoration: underline;
	}

	@media screen and (max-width: 460px) {
		padding: 0.5rem 0rem;
	}
`;

const JobCard = styled.div`
	position: relative;
	width: 27rem;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 1024px) {
		width: 16rem;
	}
`;

const jobs = [
	{
		'job-id': 'job-1',
		'job-img': '/resources/img/vacature-frontend.png',
		'header-light': 'Front-End',
		'header-bold': 'Developer',
		'cta-text': 'Front-end developers, kom op!',
		'job-descr':
			'Ons hongerige team heeft je nodig. We zoeken een extra topper voor de ontwikkeling van gave nieuwe websites en webshops. Prachtige projecten staan je te wachten.',
		'job-requirements': [
			'- Pro in HTML5 / CSS3 / JavaScript (bij voorkeur ES6)',
			'- Bekend met GIT',
			'- Een portfolio met dikke online projecten',
			'- MBO+ werk- en denkniveau',
			'- Goede beheersing van de Nederlandse taal',
			'- Pré: ervaring met PHP en object-oriented frameworks, zoals Laravel',
		],
		'job-offer': [
			'- Marktconforme Salaris',
			'- Vast contract',
			'- Volop activiteiten buiten het werk om',
			'- Flexibele werktijden & één thuiswerkdag',
			'- Ergonomische sta-zit werkplekken',
			'- Gave technische projecten',
		],
	},
	{
		'job-id': 'job-2',
		'job-img': '/resources/img/vacature-backend.png',
		'header-light': 'Back-End',
		'header-bold': 'Developer',
		'cta-text': 'Back-end developers, kom op!',
		'job-descr':
			'Ons hongerige team heeft je nodig. We zoeken een extra topper voor de ontwikkeling van gave nieuwe websites en webshops. Prachtige projecten staan je te wachten.',
		'job-requirements': [
			'- Bekend met GIT',
			'- Goede kennis van MySQL, PostgreSQL',
			'- MBO+ werk- en denkniveau',
			'- Goede beheersing van de Nederlandse taal',
			'- Pré: ervaring met PHP en object-oriented frameworks, zoals Laravel',
		],
		'job-offer': [
			'- Marktconforme Salaris',
			'- Vast contract',
			'- Volop activiteiten buiten het werk om',
			'- Flexibele werktijden & één thuiswerkdag',
			'- Ergonomische sta-zit werkplekken',
			'- Gave technische projecten',
		],
	},
];

const Vacature = () => {
	const [jobContainer, setJobContainer] = useState(0);

	const navigateJobContainer = (target) => {
		// const loader = document.querySelector('.loader');

		// loader.classList.add('active');
		// setTimeout(() => {
		// 	loader.classList.remove('active');
		// 	return;
		// }, 100);

		setJobContainer(target);
	};

	return (
		<>
			<section>
				<div className='container md:px-24'>
					<div className='w-full md:w-1/2 flex flex-col py-10 md:py-20'>
						<p className='text-tad-primary text-3xl md:text-5xl font-inter font-light'>Interested in joining</p>
						<h1 className='text-tad-black text-4xl md:text-6xl font-inter font-bold capitalize my-1 md:my-6'>
							our team
						</h1>

						<p className='paragraph-text text-tad-black font-light my-3 md:my-14'>
							Join a team and inspire the work. Discover how you can make an impact: See our areas of work, worldwide
							locations, and opportunities for students.
						</p>
					</div>
				</div>
			</section>

			<section>
				<GradientContainer className='overflow-hidden'>
					<div className='container relative z-20 md:px-24 overflow-x-visible top-services-slider'>
						<Swiper
							spaceBetween={0}
							breakpoints={{
								768: {
									spaceBetween: 40,
								},
							}}
							loop={false}
							slidesPerView={'auto'}
							onSwiper={null}
							centeredSlides={false}
						>
							<SwiperSlide className='filter drop-shadow-lg'>
								<JobCard className='rounded-lg lg:rounded-xl'>
									<img
										loading='lazy'
										src='/resources/img/vacature-frontend.png'
										alt='tad job frontend'
										className='max-w-full max-h-full'
									/>
								</JobCard>
							</SwiperSlide>
							<SwiperSlide className='filter drop-shadow-lg'>
								<JobCard className='rounded-lg lg:rounded-xl'>
									<img
										loading='lazy'
										src='/resources/img/vacature-backend.png'
										alt='tad job backend'
										className='max-w-full max-h-full'
									/>
								</JobCard>
							</SwiperSlide>
							<SwiperSlide className='filter drop-shadow-lg'>
								<JobCard className='rounded-lg lg:rounded-xl'>
									<img
										loading='lazy'
										src='/resources/img/vacature-fullstack.png'
										alt='tad job backend'
										className='max-w-full max-h-full'
									/>
								</JobCard>
							</SwiperSlide>
							<SwiperSlide className='filter drop-shadow-lg'>
								<JobCard className='rounded-lg lg:rounded-xl'>
									<img
										loading='lazy'
										src='/resources/img/vacature-designer.png'
										alt='tad job backend'
										className='max-w-full max-h-full'
									/>
								</JobCard>
							</SwiperSlide>
						</Swiper>

						<div className='w-full flex flex-col items-center py-10 md:py-16'>
							<p className='text-center text-2xl md:text-5xl text-tad-black font-inter font-light'>
								Just a few of the ways you can
							</p>
							<h1 className='text-center text-3xl md:text-6xl text-tad-black font-inter font-bold my-3 md:my-6'>
								make a difference at <span className='text-white'>tad</span>.
							</h1>
						</div>
					</div>
				</GradientContainer>
			</section>

			<section>
				<div className='container md:px-24 relative'>
					{jobs.map((job, index) => {
						return (
							<JobDescriptionContainer
								key={index}
								className={`w-full flex flex-col py-10 md:py-16 ${jobContainer === index ? 'active' : ''}`}
								id={job['job-id']}
							>
								<div className='w-full flex flex-col md:flex-row'>
									<div className='w-full md:w-2/3 flex flex-col justify-center my-4'>
										<div>
											<p className='text-2xl lg:text-5xl text-tad-primary font-inter font-light'>
												{job['header-light']}
											</p>
											<h1 className='text-3xl lg:text-6xl text-tad-black font-inter font-bold my-2 md:my-4'>
												{job['header-bold']}
											</h1>

											<p className='paragraph-text text-tad-black font-inter font-light my-4 md:my-12 leading-relaxed'>
												{job['cta-text']}
											</p>

											<p className='paragraph-text text-tad-black font-inter font-light w-5/6 leading-relaxed'>
												{job['job-descr']}
											</p>
										</div>
									</div>
									<div className='w-full md:w-1/3 flex flex-col justify-center items-center my-4'>
										<img
											loading='lazy'
											src={job['job-img']}
											alt={`tad job ${job['header-bold']}`}
											className='filter drop-shadow-lg m-auto'
										/>
									</div>
								</div>

								<div className='w-full flex flex-col md:flex-row'>
									<div className='w-full md:w-2/3 flex flex-col justify-center my-4'>
										<div>
											<p className='paragraph-text text-tad-black font-inter font-semibold leading-relaxed'>
												Wat verwachten we van jou?
											</p>
											<ul
												className='paragraph-text text-tad-black font-inter font-light mt-2 leading-relaxed'
												style={{ listStyle: 'none' }}
											>
												{job['job-requirements'].map((requirement, index) => {
													return <li key={index}>{requirement}</li>;
												})}
											</ul>
										</div>
									</div>
									<div className='w-full md:w-1/3 flex flex-col justify-center my-4'>
										<div>
											<p className='paragraph-text text-tad-black font-inter font-semibold leading-relaxed'>
												Wat krijg je van TAD?
											</p>
											<ul
												className='paragraph-text text-tad-black font-inter font-light mt-2 leading-relaxed'
												style={{ listStyle: 'none' }}
											>
												{job['job-offer'].map((offer, index) => {
													return <li key={index}>{offer}</li>;
												})}
											</ul>
										</div>
									</div>
								</div>

								<div className='w-full flex justify-between items-center pt-10 md:pt-16'>
									<JobNavigationButton
										className={jobContainer === 0 ? 'opacity-25 pointer-events-none' : 'opacity-100'}
										onClick={() => navigateJobContainer(index - 1)}
									>
										<FiArrowLeft className='w-6 mr-2' /> Previous
									</JobNavigationButton>

									<OutlineButton text={'apply'} extraClass={'m-0 md:md-2 text-tad-primary'} outlineColor={'#24CE7B'}>
										{/* <FiArrowRight className='icon' /> */}
									</OutlineButton>

									<JobNavigationButton
										className={jobContainer === jobs.length - 1 ? 'opacity-25 pointer-events-none' : 'opacity-100'}
										onClick={() => navigateJobContainer(index + 1)}
									>
										Next <FiArrowRight className='w-6 ml-2' />
									</JobNavigationButton>
								</div>
							</JobDescriptionContainer>
						);
					})}
				</div>
			</section>

			<section>
				<Footer type='dark' logoType='dark' logoClass='hidden' usedForNav={false}>
					<div className='lets-talk-section'>
						<h1 className='heading wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
							Want to work together?
						</h1>
						<h1 className='subheading wow animate__animated animate__fadeInUp' data-wow-delay='0.5s'>
							Let's talk.
						</h1>

						<div className='w-full flex flex-row justify-center items-center'>
							<NavLink to='/contact'>
								<SolidButton text={'start a project'} extraClass={'bg-tad-primary text-white px-6'} />
							</NavLink>
							<a href='tel:+5978925686'>
								<OutlineButton
									text={null}
									extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-7 sm:px-8'}
									outlineColor={'#24CE7B'}
								>
									<div className='text'>+597 892-5686</div>
									<FiPhone className='icon' />
								</OutlineButton>
							</a>
						</div>
					</div>
				</Footer>
			</section>
		</>
	);
};

export default Vacature;
