import React, { useEffect } from 'react';

import WOW from 'wowjs';
import 'animate.css';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? REACT ICONS
import { FiArrowRight } from 'react-icons/fi';

const Article = styled.div`
	position: relative;
	width: 350px;
	/* height: 375px; */
	height: 330px;

	background: ${({ background }) => background.primary};
	background: linear-gradient(
		45deg,
		${({ background }) => background.primary} 30%,
		${({ background }) => background.secondary} 100%
	);

	@media screen and (max-width: 768px) {
		width: 250px;
		height: 300px;
	}
`;

const Img = styled.img`
	filter: invert(1);
`;

const HomeService = ({ id, pallete, icon, heading, text, subText }) => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<Article background={pallete} className='p-7 md:p-10 flex flex-col rounded-2xl relative'>
			<Img src={icon} alt='' className='w-16 h-auto text-white wow animate__animated animate__fadeIn' data-wow-duration="1.5s" />
			<h1 className='text-xl md:text-2xl text-white font-inter font-medium mt-6 md:mt-12 capitalize wow animate__animated animate__fadeInUp' data-wow-delay="0.3s">{heading}</h1>
			<p className='paragraph-text text-white font-inter mt-2 wow animate__animated animate__fadeInUp' data-wow-delay="0.5s">{text}</p>
			<p className='paragraph-text text-white font-inter mt-2 wow animate__animated animate__fadeInUp' data-wow-delay="0.7s">{subText}</p>
			<div className='hidden flex-REMOVE flex-row flex-wrap absolute bottom-10 wow animate__animated animate__fadeInUp' data-wow-delay="0.9s">
				<FiArrowRight className='w-6 h-6 text-white' />
			</div>
		</Article>
	);
};

export default HomeService;
