import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

//? PAGES
import HomePage from './pages/Home';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import VacaturePage from './pages/Vacature';
import PortfolioPage from './pages/Portfolio';
import ProjectSmartConnexxionzPage from './pages/ProjectSmartConnexxionz';
import ProductFlexPosPage from './pages/ProductFlexPos';

//? LARGE COMPONENTS
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ScrollIndicator from './components/ScrollIndicator';

import './App.css';
import ScrollToTop from './ScrollToTop';

function App() {
	const [menuOpen, setMenuOpen] = useState(true);

	const location = useLocation();

	useEffect(() => {
		fadeInLoader()
	}, [location.key]);

	const fadeInLoader = () => {
		const loader = document.querySelector('.loader');

		loader.classList.add('active');
		setTimeout(() => {
			loader.classList.remove('active');
			return;
		}, 200);
	};

	const toggleSidebar = () => {
		setMenuOpen(!menuOpen);

		const sidebar = document.querySelector('.sidebar');
		const content = document.querySelector('#content');

		sidebar.classList.toggle('translate-x-full');
		content.classList.toggle('opacity-50');

		menuOpen ? disablePageScroll() : enablePageScroll()
	};

	return (
		<>
			<Sidebar toggleSidebar={toggleSidebar} />
			<div id='content' className='transition duration-500'>
				<ScrollToTop />
				<Navbar toggleSidebar={toggleSidebar} isMenuOpen={menuOpen} />
				<div id='content-body'>
					<ScrollIndicator />
					<Switch>
						<Route path='/' exact component={HomePage} />
						<Route path='/portfolio' component={PortfolioPage} />
						<Route path='/contact' component={ContactPage} />
						<Route path='/about' component={AboutPage} />
						<Route path='/jobs' component={VacaturePage} />
						<Route path='/project-smartconnexxionz' component={ProjectSmartConnexxionzPage} />
						<Route path='/product-flexpos' component={ProductFlexPosPage} />
					</Switch>
				</div>
			</div>
		</>
	);
}

export default App;
