import React from 'react';

//? STYLED COMPONENTS
import styled from 'styled-components';

const Container = styled.div`
	background-color: rgb(0, 0, 0, 0.5);
	width: 35rem;
	height: auto;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: auto;
	}
`;

const IconContainer = styled.div`
	width: 5rem;
	height: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 768px) {
		width: 5rem;
		height: 4rem;
	}
`;

const TextContainer = styled.div`
	width: calc(100% - 5rem);

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const WhyService = ({ icon, title, description }) => {
	return (
		<Container className='rounded-2xl flex flex-col relative p-6 lg:p-10'>
			<div className='w-full h-auto md:h-24 flex flex-row items-center'>
				<IconContainer className='bg-tad-primary rounded-xl md:rounded-2xl p-2 md:p-3'>
					<img loading='lazy' src={icon} alt='' className='w-10 h-10 md:w-12 md:h-12 text-white filter invert' />
				</IconContainer>
				<TextContainer className='pl-5 text-xl md:text-3xl leading-none text-white font-inter font-semibold'>
					<p>{title}</p>
				</TextContainer>
			</div>
			<div className='h-full pt-4 lg:pt-8'>
				<p className='text-base md:text-lg lg:text-xl xl:text-2xl text-white font-inter font-light leading-relaxed lg:leading-loose'>{description}</p>
			</div>
		</Container>
	);
};

export default WhyService;
