import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import emailjs from 'emailjs-com';

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
import Input from '../components/Inputs/Input';
import Textarea from '../components/Inputs/Textarea';
import SolidButton from '../components/Button/SolidButton';
import OutlineButton from '../components/Button/OutlineButton';
import Footer from '../components/Footer';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? REACT ICONS
import { FiPhone, FiCheck, FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';

const MessageBox = styled.div`
	.success-message,
	.warning-message,
	.error-message {
		display: none;
	}

	&.success {
		color: #24ce7b !important;

		.success-message {
			display: flex !important;
		}
	}

	&.warning {
		color: #ea891b !important;

		.warning-message {
			display: flex !important;
		}
	}

	&.error {
		color: #ee2828 !important;

		.error-message {
			display: flex !important;
		}
	}
`;

const Contact = () => {
	const sendEmail = (e) => {
		e.preventDefault();

		const waitLoader = document.querySelector('.wait-loader');
		waitLoader.classList.add('active');

		emailjs.sendForm('service_jwesw9c', 'template_iixhull', e.target, 'user_bMEWZAqYFMRriGgi5FKyY').then(
			(result) => {
				console.log(result.text);
				document.querySelector('#message-box-container').classList.add('success');
				document.querySelector(
					'.message-text'
				).innerHTML = `We have received your message! We will contact you as soon as possible.`;

				waitLoader.classList.remove('active');
			},
			(error) => {
				console.log(error.text);
				document.querySelector('#message-box-container').classList.add('error');
				document.querySelector('.message-text').innerHTML = 'Oops, something went wrong. Please try again later';

				waitLoader.classList.remove('active');
			}
		);

		// setTimeout(() => {
		// 	document.querySelector('#message-box-container').classList.add('success');
		// 	document.querySelector('.message-text').innerHTML = 'Success Message';
		// 	waitLoader.classList.remove('active');
		// }, 4000);

		e.target.reset();
	};

	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			<section>
				<div className='container md:px-24 w-full flex flex-col md:flex-row py-12 md:pt-32 md:pb-56'>
					<div className='w-full md:w-2/5 flex flex-col justify-center items-start p-2 md:p-0'>
						<h1
							className='text-4xl md:text-5xl font-inter text-tad-primary font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							Let's
						</h1>
						<h1
							className='text-5xl md:text-7xl font-inter text-tad-black font-semibold mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							Talk
						</h1>

						<p
							className='mt-5 md:mt-16 font-inter paragraph-text text-normal wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							Tell us all about your project right here, or send us an email at{' '}
							<a href='mailto:hello@tad.sr' className='text-tad-primary hover:underline'>
								hello@tad.sr
							</a>
						</p>
					</div>
					<div className='w-full mt-14 md:mt-0 md:w-3/5 flex flex-col justify-center items-center pl-0 md:pl-14'>
						<form onSubmit={sendEmail} className='wow animate__animated animate__fadeIn' data-wow-duration='1.5s'>
							<div className='w-full flex flex-row'>
								<div className='w-1/2 p-2 md:p-4'>
									<Input type='text' name='from_name' placeholder='Fullname*' isRequired={true} />
								</div>
								<div className='w-1/2 p-2 md:p-4'>
									<Input type='text' name='from_company' placeholder='Company Name*' isRequired={false} />
								</div>
							</div>
							<div className='w-full flex flex-row'>
								<div className='w-full p-2 md:p-4'>
									<Input type='email' name='from_email' placeholder='Email*' isRequired={true} />
								</div>
							</div>
							<div className='w-full flex flex-row'>
								<div className='w-full p-2 md:p-4'>
									<Textarea name='message' placeholder='Tell us about your project*' rows='5' isRequired={true} />
								</div>
							</div>
							<div className='w-full flex flex-row'>
								<div className='w-full p-2 md:p-4'>
									<SolidButton type={'submit'} text={'submit'} extraClass={'bg-tad-primary text-white px-10 w-full'} />
								</div>
							</div>
						</form>
						<div className='w-full flex flex-row'>
							<div className='w-full p-2 md:p-4'>
								<MessageBox id='message-box-container' className='w-full flex justify-center items-center text-center'>
									<div className='success-message flex'>
										<FiCheck className='w-6 h-6 mx-2 hidden' />
										<p className='text-base font-inter font-medium message-text'>...</p>
									</div>
									<div className='warning-message flex'>
										<FiAlertTriangle className='w-6 h-6 mx-2 hidden' />
										<p className='text-base font-inter font-mediu message-textm'>...</p>
									</div>
									<div className='error-message flex'>
										<FiAlertCircle className='w-6 h-6 mx-2 hidden' />
										<p className='text-base font-inter font-mediu message-textm'>...</p>
									</div>
								</MessageBox>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer type='dark' logoType='white' logoClass='hidden' usedForNav={false}>
				<div className='lets-talk-section'>
					<h1 className='heading wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
						Want to work together?
					</h1>
					<h1 className='subheading wow animate__animated animate__fadeInUp' data-wow-delay='0.5s'>
						Let's talk.
					</h1>

					<div className='w-full flex flex-row justify-center items-center'>
						<NavLink to='/contact'>
							<SolidButton text={'start a project'} extraClass={'bg-tad-primary text-white px-6'} />
						</NavLink>
						<a href='tel:+5978925686'>
							<OutlineButton
								text={null}
								extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-7 sm:px-8'}
								outlineColor={'#24CE7B'}
							>
								<div className='text'>+597 892-5686</div>
								<FiPhone className='icon' />
							</OutlineButton>
						</a>
					</div>
				</div>
			</Footer>
		</>
	);
};

export default Contact;
