import React from 'react'

//? STYLED COMPONENTS
import styled from "styled-components";

const Card = styled.div`
	width: 30%;
	height: auto;
	background: transparent;
	display: flex;
	flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 80%;
        margin-bottom: 1.5rem;
    }

	.img-container {
		height: 30rem;

		@media screen and (max-width: 768px) {
			height: 25rem;
		}
	}
`

const Staff = ({id, name, position, img}) => {
    return (
        <Card>
            <div className="img-container w-full rounded-lg md:rounded-xl bg-blue-300 overflow-hidden">
                <img src="https://picsum.photos/1080/1920" alt={`staff ${id}`} className="w-full object-cover" />
            </div>
            <div className="w-full flex-col justify-center pt-5 md:pt-10">
                <p className="text-lg md:text-xl text-white text-center font-inter font-bold">{ name }</p>
                <p className="paragraph-text text-white text-center font-inter font-normal my-2">{position}</p>
            </div>
        </Card>
    )
}

export default Staff
