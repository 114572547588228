import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
import SolidButton from '../components/Button/SolidButton';
import OutlineButton from '../components/Button/OutlineButton';
import SingleClient from '../components/Client';
import Project from '../components/Project';
import Footer from '../components/Footer';

//? STYLED COMPONENTS
// import styled from 'styled-components';

//? REACT ICONS
import { FiPhone, FiArrowRight } from 'react-icons/fi';

const clients = [
	{
		id: 1,
		logo: '/resources/img/client-smart-logo.png',
		pallete: {
			primary: '#1EB6EC',
			secondary: '#9BE2FE',
		},
	},
	{
		id: 2,
		logo: '/resources/img/client-slimbouw-logo.png',
		pallete: {
			primary: '#EE3342',
			secondary: '#FF9EA6',
		},
	},
	{
		id: 3,
		logo: '/resources/img/client-ride-logo.png',
		pallete: {
			primary: '#000000',
			secondary: '#00000050',
		},
	},
	{
		id: 4,
		logo: '/resources/img/client-panam-logo.png',
		pallete: {
			primary: '#273348',
			secondary: '#5A6D8F',
		},
	},
	{
		id: 5,
		logo: '/resources/img/client-lia-logo.png',
		pallete: {
			primary: '#282828',
			secondary: '#686868',
		},
	},
	{
		id: 6,
		logo: '/resources/img/client-cmc-logo.png',
		pallete: {
			primary: '#08498C',
			secondary: '#94B9E0',
		},
	},
];

const projects = [
	{
		id: 1,
		title: 'Smart Connexxionz',
		descr:
			'Rebranding, strategy and design for one of Suriname’s largest digital shopping platforms, catering to millions of visitors. Here’s how we helped Smart ConnexXionZ provide their services.',
		logo: '/resources/img/project-smart-logo.png',
		pallete: {
			primary: '#1EB6EC',
			secondary: '#9BE2FE',
			tag: '#5BCCF5',
		},
		img: '/resources/img/project-smart-banner.png',
		orientation: 'flex-row',
		link: '/project-smartconnexxionz',
	},
	{
		id: 2,
		title: 'Slimbouw',
		descr:
			'Website, marketing and design for one of Suriname’s leading construction groups, catering to millions of visitors. Here’s how we helped Slimbouw Smart Constructions provide their services.',
		logo: '/resources/img/project-slimbouw-logo.png',
		pallete: {
			primary: '#EE3342',
			secondary: '#FF9FA7',
			tag: '#F87F89',
		},
		img: '/resources/img/project-slimbouw-banner.png',
		orientation: 'flex-row',
		link: '#',
	},
	{
		id: 3,
		title: 'Legacy In Action',
		descr:
			'Website, marketing and design for one of Suriname’s top visual companies, catering to millions of visitors. Here’s how we helped My Lia provide their services and get their message across.',
		logo: '/resources/img/project-lia-logo.png',
		pallete: {
			primary: '#282828',
			secondary: '#686868',
			tag: '#686868',
		},
		img: '/resources/img/project-lia-banner.png',
		orientation: 'flex-row',
		link: '#',
	},
	{
		id: 4,
		title: 'Pan American Parts',
		descr:
			'Website, marketing and design for one of Suriname’s leading CAR PARTS resellers, catering to millions of visitors. Here’s how we helped Pan American Parts provide their services.',
		logo: '/resources/img/project-panam-logo.png',
		pallete: {
			primary: '#273348',
			secondary: '#5A6D8F',
			tag: '#5A6D8F',
		},
		img: '/resources/img/project-panam-banner.png',
		orientation: 'flex-row',
		link: '#',
	},
	{
		id: 5,
		title: 'CMC',
		descr:
			'Website, marketing and design for one of Suriname’s top media & print consultants, catering to millions of visitors. Here’s how we helped CMC Suriname provide their services.',
		logo: '/resources/img/project-cmc-logo.png',
		pallete: {
			primary: '#08498C',
			secondary: '#94B9E0',
			tag: '#94B9E0',
		},
		img: '/resources/img/project-cmc-banner.png',
		orientation: 'flex-row',
		link: '#',
	},
	{
		id: 6,
		title: 'Ride',
		descr:
			'Mobile application and design for one of Suriname’s biggest Online Taxi Services, catering to millions of Riders. Here’s how we helped Ride Suriname provide their services.',
		logo: '/resources/img/project-ride-logo.png',
		pallete: {
			primary: '#000000',
			secondary: '#808080',
			tag: '#808080',
		},
		img: '/resources/img/project-ride-banner.png',
		orientation: 'flex-row',
		link: '#',
	},
];

const Portfolio = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			{/* <section className='h-auto md:h-hero hero-section'>
				<div className='container relative flex flex-col-reverse md:flex-row h-full py-5 md:px-24 md:py-0'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-center py-5'>
						<h1
							className='text-4xl lg:text-6xl font-inter text-tad-primary font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							Our
						</h1>
						<h1
							className='text-5xl lg:text-7xl font-inter text-tad-black font-bold mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							Work
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							Here are some of the featured projects we’ve been working on. Contact us to get more information about our
							other work and previous clients.
						</p>
					</div>
					<div className='relative w-full md:w-1/2 flex flex-col items-start justify-center'>
						<img
							loading='lazy'
							src='/images/about/aboutHero.png'
							alt=''
							className='relative w-full h-auto inline md:absolute md:transform md:scale-180 md:-right-3/4 wow animate__animated animate__fadeIn'
							data-wow-duration='1.5s'
						/>
					</div>
				</div>
			</section> */}

			<section className='h-auto xl:h-hero hero-section overflow-x-hidden'>
				<div className='container relative flex flex-col-reverse md:flex-row h-full md:px-24 overflow-x-visible'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-center py-5'>
						<h1
							className='text-4xl md:text-5xl font-inter text-tad-primary font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							View our
						</h1>
						<h1
							className='text-5xl md:text-7xl font-inter text-tad-black font-semibold mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							featured projects
						</h1>
						<p
							className='text-tad-black font-inter font-light paragraph-text leading-loose wow animate__animated animate__fadeInUp'
							data-wow-delay='0.7s'
						>
							Here are some of the featured projects we’ve been working on. Contact us to get more information about our
							other work and previous clients.
						</p>
					</div>
					<div className='relative w-full md:w-1/2 flex flex-col items-start justify-center py-0'>
						<picture
							className='relative w-full h-auto inline md:absolute transform scale-107 md:scale-130 xl:scale-200 md:-right-2/4 wow animate__animated animate__fadeIn'
							data-wow-duration='1.5s'
						>
							<source media='(min-width: 768px)' srcSet='/resources/img/portfolio-hero-banner.png' />
							<img loading='lazy' src='/resources/img/portfolio-hero-banner-mobile.png' alt='' />
						</picture>
					</div>
				</div>
			</section>

			<section>
				<div className='container md:px-24 overflow-x-visible'>
					<div className='project-section'>
						{projects.map((project, index) => {
							return (
								<Project
									key={index}
									title={project.title}
									descr={project.descr}
									logo={project.logo}
									pallete={project.pallete}
									img={project.img}
									orientation={project.orientation}
									link={project.link}
								/>
							);
						})}
					</div>

					<div className='relative my-14 xl:my-20 flex items-center justify-center'>
						<div className='flex wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
							<NavLink to='/contact'>
								<SolidButton text={'contact us'} extraClass={'bg-tad-primary m-2 text-white px-6'} />
							</NavLink>
							<NavLink to='/about'>
								<OutlineButton text={'more services'} extraClass={'m-2 text-tad-primary'} outlineColor={'#24CE7B'}>
									<FiArrowRight className='icon' />
								</OutlineButton>
							</NavLink>
						</div>
					</div>
				</div>
			</section>

			<div className='section-border'>
				<hr />
			</div>

			<section>
				<div className='container flex flex-col-reverse md:flex-row py-10 md:px-24 md:py-20'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'>
						<h1
							className='text-4xl lg:text-5xl text-tad-primary font-inter font-light capitalize wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							Trusted by
						</h1>
						<h1
							className='text-4xl lg:text-5xl text-tad-black font-inter font-semibold capitalize mt-3 md:mt-4 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							awesome clients
						</h1>
					</div>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-start'></div>
				</div>
				<div className='container flex flex-col-reverse md:flex-row md:px-24 pb-10 md:pb-20'>
					<div className='w-full flex flex-row flex-wrap'>
						{clients.map((client, index) => {
							return <SingleClient key={index} id={client.id} pallete={client.pallete} img={client.logo} />;
						})}
					</div>
				</div>
			</section>

			<Footer type='dark' logoType='dark' logoClass='hidden' usedForNav={false}>
				<div className='lets-talk-section'>
					<h1 className='heading wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
						Want to work together?
					</h1>
					<h1 className='subheading wow animate__animated animate__fadeInUp' data-wow-delay='0.5s'>
						Let's talk.
					</h1>

					<div className='w-full flex flex-row justify-center items-center'>
						<NavLink to='/contact'>
							<SolidButton text={'start a project'} extraClass={'bg-tad-primary text-white px-6'} />
						</NavLink>
						<a href='tel:+5978925686'>
							<OutlineButton
								text={null}
								extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-7 sm:px-8'}
								outlineColor={'#24CE7B'}
							>
								<div className='text'>+597 892-5686</div>
								<FiPhone className='icon' />
							</OutlineButton>
						</a>
					</div>
				</div>
			</Footer>
		</>
	);
};

export default Portfolio;
