import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import WOW from 'wowjs';
import 'animate.css';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? COMPONENTS
import SolidButton from '../components/Button/SolidButton';
import OutlineButton from '../components/Button/OutlineButton';
import Footer from '../components/Footer';

//? REACT ICONS
import { FiGlobe, FiPhone } from 'react-icons/fi';

//? SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const CustomColorText = styled.p`
	color: #1eb6ec;
	font-size: 2rem;

	@media screen and (min-width: 768px) {
		font-size: 48px;
	}
`;

const GradientContainer = styled.div`
	max-height: 863px;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 2rem;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(30, 182, 236);
		background: linear-gradient(90deg, rgba(30, 182, 236, 1) 0%, rgba(155, 226, 254, 1) 100%);
		width: 100%;
		height: 70%;
		z-index: 9;

		@media screen and (max-width: 768px) {
			height: 60%;
		}
	}

	img {
		position: relative;
		z-index: 10;
		width: 100%;
		height: auto;
	}
`;

const ClientTag = styled.div`
	background: #5bccf5;
	margin-right: 1rem;
`;

const ProjectSmartConnexxionz = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			<section>
				<div className='container md:px-24'>
					<div className='w-full flex flex-col pt-6 md:pt-20 pb-10 md:pb-16'>
						<CustomColorText
							className='font-inter font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							Largest digital shopping platform{' '}
						</CustomColorText>
						<p
							className='text-tad-black text-4xl md:text-6xl font-inter font-bold my-4 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							Smart ConnexXionZ
						</p>

						<div className='w-full sm:w-4/6 mt-5 md:mt-16'>
							<p
								className='text-tad-black text-sm lg:text-lg font-inter paragraph-text leading-loose lg:leading-loose wow animate__animated animate__fadeInUp'
								data-wow-delay='0.7s'
							>
								Rebranding and generating exponential user growth poses challenges. That's why Smart ConnexXionZ, one of
								Suriname’s largest digital shopping platforms, chose <span className='font-bold'>tad</span>
								<span className='text-tad-primary'>.</span> to seamlessly design their website and elevate their user
								experience.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section>
				<GradientContainer>
					<div className='container'>
						<img loading='lazy' src='/resources/img/product-smart-banner-gradient.png' alt='' className='transform scale-130 sm:scale-100' />
					</div>
				</GradientContainer>
			</section>

			<section>
				<div className='container md:px-24'>
					<div className='w-full flex flex-col-reverse md:flex-row py-5 md:py-10'>
						<div className='w-full py-5 sm:py-24 sm:w-1/2 flex flex-col'>
							<CustomColorText
								className='font-inter font-light wow animate__animated animate__fadeInUp'
								data-wow-delay='0.3s'
							>
								Meet
							</CustomColorText>
							<p
								className='text-tad-black text-4xl md:text-6xl font-inter font-bold my-4 wow animate__animated animate__fadeInUp'
								data-wow-delay='0.5s'
							>
								The Client
							</p>

							<div className='w-full mt-5 md:mt-16'>
								<p
									className='text-tad-black text-sm lg:text-lg font-inter paragraph-text leading-loose lg:leading-loose wow animate__animated animate__fadeInUp'
									data-wow-delay='0.7s'
								>
									With over 10.000 technology items available in Suriname, Smart ConnexXionZ has established itself as
									one of the largest digital shopping platforms. Our mission is to centralize all technology available
									on the market, whether its phones, tablets, laptops and more. Smart ConnexXionZ offers the most
									diverse and complete content to technology seekers. Their mission is to centralize available
									technology in one, easy-to-use place.
								</p>
							</div>

							<div className='w-full mt-10 md:mt-20 wow animate__animated animate__fadeInUp' data-wow-delay='0.9s'>
								<p className='text-tad-black text-sm md:text-lg font-inter paragraph-text'>What We Did:</p>
								<div className='w-full flex flex-row mt-2 sm:mt-4'>
									<ClientTag className='label-tags'>
										website
									</ClientTag>
									<ClientTag className='label-tags'>
										marketing
									</ClientTag>
								</div>
							</div>
						</div>
						<div className='w-full py-5 sm:py-24 sm:w-1/2 flex justify-center items-center'>
							<img
								loading='lazy'
								src='/resources/img/client-smart-logo-faded.png'
								alt=''
								className='w-5/6 wow animate__animated animate__fadeInUp'
								data-wow-duration='1.5s'
							/>
						</div>
					</div>
				</div>
			</section>

			<section>
				<img
					loading='lazy'
					src='/resources/img/product-smart-location.png'
					alt=''
					className='w-full object-cover'
				/>
			</section>

			<section>
				<div className='container md:px-24 py-10 md:py-20'>
					<div className='w-full flex flex-col pb-6 md:pb-14'>
						<div className='w-full flex justify-end'>
							<div className='flex flex-col text-right'>
								<p
									className='text-2xl lg:text-4xl text-tad-black font-inter font-semibold capitalize wow animate__animated animate__fadeInUp'
									data-wow-delay='0.3s'
								>
									<span style={{ color: '#1EB6EC' }}>home</span> page
								</p>
								<p
									className='text-sm sm:text-base text-tad-black font-inter font-light mt-2 wow animate__animated animate__fadeInUp'
									data-wow-delay='0.5s'
								>
									Here are some home features.
								</p>
							</div>
						</div>
						<div className='w-full flex py-5 md:py-10'>
							<img
								loading='lazy'
								src='/resources/img/product-smart-web-1.png'
								alt=''
								className='w-full object-cover'
							/>
						</div>
					</div>

					<div className='w-full flex flex-col pb-6 md:pb-14'>
						<div className='w-full flex justify-start'>
							<div className='flex flex-col text-left'>
								<p
									className='text-2xl lg:text-4xl text-tad-black font-inter font-semibold capitalize wow animate__animated animate__fadeInUp'
									data-wow-delay='0.3s'
								>
									<span style={{ color: '#1EB6EC' }}>shopping</span> page
								</p>
								<p
									className='text-sm sm:text-base text-tad-black font-inter font-light mt-2 wow animate__animated animate__fadeInUp'
									data-wow-delay='0.5s'
								>
									Here are some shopping features.
								</p>
							</div>
						</div>
						<div className='w-full flex py-5 md:py-10'>
							<img
								loading='lazy'
								src='/resources/img/product-smart-web-2.png'
								alt=''
								className='w-full object-cover'
							/>
						</div>
					</div>

					<div className='w-full flex flex-col pb-6 md:pb-14'>
						<div className='w-full flex justify-end'>
							<div className='flex flex-col text-right'>
								<p
									className='text-2xl lg:text-4xl text-tad-black font-inter font-semibold capitalize wow animate__animated animate__fadeInUp'
									data-wow-delay='0.3s'
								>
									<span style={{ color: '#1EB6EC' }}>product</span> page
								</p>
								<p
									className='text-sm sm:text-base text-tad-black font-inter font-light mt-2 wow animate__animated animate__fadeInUp'
									data-wow-delay='0.5s'
								>
									Here are some product details.
								</p>
							</div>
						</div>
						<div className='w-full flex py-5 md:py-10'>
							<img
								loading='lazy'
								src='/resources/img/product-smart-web-3.png'
								alt=''
								className='w-full object-cover'
							/>
						</div>
					</div>

					<div
						className='relative my-5 xl:my-14 flex items-center justify-center wow animate__animated animate__fadeInUp'
						data-wow-delay='0.3s'
					>
						<NavLink to='/contact'>
							<SolidButton fillColor={'#1EB6EC'} text={'contact us'} extraClass={'bg-tad-primary text-white px-4 sm:px-6 text-xs xl:text-base'} />
						</NavLink>

						<a href='https://smartconnexxionz.com' target='_blank' rel='noreferrer'>
							<OutlineButton
								text={null}
								extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-6 sm:px-8 text-xs xl:text-base'}
								outlineColor={'#1EB6EC'}
								textColor={'#1EB6EC'}
							>
								<div className="text">smartconnexxionz.com</div>
								<FiGlobe className='icon' />
							</OutlineButton>
						</a>
					</div>
				</div>
			</section>

			<div className='section-border'>
				<hr />
			</div>

			<section>
				<div className='container md:px-24'>
					<div className='w-full flex flex-col mt-10 md:mt-16'>
						<CustomColorText
							className='font-inter font-light wow animate__animated animate__fadeInUp'
							data-wow-delay='0.3s'
						>
							The results
						</CustomColorText>
						<p
							className='text-tad-black text-4xl md:text-6xl font-inter font-bold my-4 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.5s'
						>
							Profit & satisfaction
						</p>
						<div className='w-full sm:w-4/6 mt-5 md:mt-16'>
							<p
								className='text-tad-black md:text-lg font-inter paragraph-text wow animate__animated animate__fadeInUp'
								data-wow-delay='0.7s'
							>
								Smart ConnexXionZ transitioned to their new website with ease, and their user base loved it. There
								Website visitors rates increased by 10%, resulting in an 10% revenue increase. Conversion rates from
								visitors to users increased by 10%. Living up to the largest digital shopping platforms in Suriname.
							</p>
						</div>
					</div>
					<div className='w-full flex my-10 md:my-20'>
						<div className='w-1/3 flex flex-col justify-center items-center'>
							<p
								style={{ color: '#1eb6ec' }}
								className='text-4xl md:text-6xl font-inter font-semibold wow animate__animated animate__fadeInUp'
								data-wow-delay='0.9s'
							>
								+10<span className='text-xl sm:text-2xl'>%</span>
							</p>
							<p style={{ color: '#1eb6ec' }} className='text-sm md:text-base font-inter font-light capitalize'>
								website visitors
							</p>
						</div>
						<div className='w-1/3 flex flex-col justify-center items-center'>
							<p
								style={{ color: '#1eb6ec' }}
								className='text-4xl md:text-6xl font-inter font-semibold wow animate__animated animate__fadeInUp'
								data-wow-delay='0.9s'
							>
								+10<span className='text-xl sm:text-2xl'>%</span>
							</p>
							<p style={{ color: '#1eb6ec' }} className='text-sm md:text-base font-inter font-light capitalize'>
								revenue
							</p>
						</div>
						<div className='w-1/3 flex flex-col justify-center items-center'>
							<p
								style={{ color: '#1eb6ec' }}
								className='text-4xl md:text-6xl font-inter font-semibold wow animate__animated animate__fadeInUp'
								data-wow-delay='0.9s'
							>
								+10<span className='text-xl sm:text-2xl'>%</span>
							</p>
							<p style={{ color: '#1eb6ec' }} className='text-sm md:text-base font-inter font-light capitalize'>
								conversion rate
							</p>
						</div>
					</div>
				</div>
			</section>

			<section style={{ backgroundColor: '#A5D9EC' }}>
				<div className='w-full relative overflow-hidden pt-12 md:pt-20'>
					<div className='container md:px-24'>
						<p className='text-2xl lg:text-4xl text-white font-inter font-semibold capitalize'>mobile view</p>
						<p className='text-sm sm:text-base text-white font-inter font-light mt-2'>
							Here are some of the mobile screens
						</p>
					</div>
					<div className='container md:px-24 overflow-x-visible py-5 md:py-10'>
						<Swiper
							spaceBetween={20}
							freeMode={true}
							slidesPerView={'auto'}
							breakpoints={{
								640: {
									spaceBetween: 30,
								},
								768: {
									spaceBetween: 50,
								},
								1024: {
									spaceBetween: 70,
								},
							}}
							onSwiper={null}
							centeredSlides={false}
							className=''
						>
							<SwiperSlide className='w-44 md:w-80 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-mobile-1.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-44 md:w-80 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-mobile-2.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-44 md:w-80 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-mobile-3.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-44 md:w-80 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-mobile-4.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-44 md:w-80 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-mobile-5.png' alt='' className='w-full' />
							</SwiperSlide>
						</Swiper>
					</div>
				</div>

				<div className='w-full relative h-10 md:h-16'></div>

				<div className='w-full relative overflow-hidden pb-12 md:pb-20'>
					<div className='container md:px-24'>
						<p className='text-2xl lg:text-4xl text-white font-inter font-semibold capitalize'>designs</p>
						<p className='text-sm sm:text-base text-white font-inter font-light mt-2'>Here are some of designs</p>
					</div>
					<div className='container md:px-24 overflow-x-visible py-5 md:py-10'>
						<Swiper
							spaceBetween={30}
							freeMode={true}
							slidesPerView={'auto'}
							onSwiper={null}
							centeredSlides={false}
							className=''
						>
							<SwiperSlide className='w-64 md:w-96 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-design-1.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-64 md:w-96 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-design-2.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-64 md:w-96 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-design-3.png' alt='' className='w-full' />
							</SwiperSlide>
							<SwiperSlide className='w-64 md:w-96 h-auto'>
								<img loading='lazy' src='/resources/img/product-smart-design-4.png' alt='' className='w-full' />
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</section>

			<section>
				<Footer logoClass='hidden' usedForNav={false}>
					<div className='lets-talk-section'>
						<h1 className='heading wow animate__animated animate__fadeInUp' data-wow-delay='0.3s'>
							Want to work together?
						</h1>
						<h1 className='subheading wow animate__animated animate__fadeInUp' data-wow-delay='0.5s'>
							Let's talk.
						</h1>

						<div className='w-full flex flex-row justify-center items-center'>
							<NavLink to='/contact'>
								<SolidButton text={'start a project'} extraClass={'bg-tad-primary text-white px-6'} />
							</NavLink>
							<a href='tel:+5978925686'>
								<OutlineButton
									text={null}
									extraClass={'ml-2 sm:ml-4 text-tad-primary with-icon px-7 sm:px-8'}
									outlineColor={'#24CE7B'}
								>
									<div className='text'>+597 892-5686</div>
									<FiPhone className='icon' />
								</OutlineButton>
							</a>
						</div>
					</div>
				</Footer>
			</section>
		</>
	);
};

export default ProjectSmartConnexxionz;
