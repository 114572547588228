import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

import WOW from 'wowjs';
import 'animate.css';

//? COMPONENTS
import OutlineButton from '../Button/OutlineButton/index';
import SolidButton from '../Button/SolidButton/index';

const Hero = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			<section className='h-auto xl:h-hero hero-section overflow-x-hidden'>
				<div className='container relative flex flex-col-reverse md:flex-row h-full md:px-24'>
					<div className='w-full md:w-1/2 flex flex-col items-start justify-center py-5'>
						<h1 className="text-sm md:text-base text-tad-black font-inter font-bold mb-5 lg:mb-20 uppercase">based in paramaribo, suriname 🇸🇷</h1>
						<h1 className='text-5xl xl:text-7xl font-inter text-tad-black font-semibold mb-12 xl:mb-48'>
							<span className='wow animate__animated animate__fadeInUp' data-wow-delay='0.3s' data-wow-duration='0.8s'>
								We make
							</span>
							<br />
							<span
								className='text-tad-primary wow animate__animated animate__fadeInUp'
								data-wow-delay='0.5s'
								data-wow-duration='0.8s'
							>
								out of the box
							</span>
							<div className='wow animate__animated animate__fadeInUp' data-wow-delay='0.7s' data-wow-duration='0.8s'>
								<Typewriter
									options={{
										strings: ['designs', 'websites', 'apps'],
										autoStart: true,
										loop: true,
										delay: 'natural',
										pauseFor: 2000,
									}}
								/>
							</div>
						</h1>
						<div
							className='w-full flex sm:flex-row-reverse justify-between mt-5 sm:mt-0 wow animate__animated animate__fadeInUp'
							data-wow-delay='0.9s'
							data-wow-duration='0.8s'
						>
							<NavLink to='/contact'>
								<SolidButton text={'contact us'} extraClass={'bg-tad-primary text-white inline px-8 md:hidden'} />
							</NavLink>
							<NavLink to='/portfolio'>
								<OutlineButton text={null} extraClass={'text-tad-primary px-8 with-icon'} outlineColor={'#24CE7B'}>
									<div className='text'>view our work</div>
									<img src='/resources/img/tad-logo-outline.png' alt='' className='icon' />
								</OutlineButton>
							</NavLink>
						</div>
					</div>
					<div className='relative w-full md:w-1/2 flex flex-col items-start justify-center py-0 overflow-visible'>
						<picture
							className='relative w-full h-auto inline md:absolute transform scale-107 md:scale-130 xl:scale-180 md:-right-2/4 wow animate__animated animate__fadeIn'
							data-wow-duration='1.5s'
						>
							<source media='(min-width: 768px)' srcSet='/resources/img/home-hero-banner.png' />
							<img
								loading='lazy'
								src='/resources/img/home-hero-banner-mobile.png'
								alt=''
							/>
						</picture>
					</div>
				</div>
			</section>
		</>
	);
};

export default Hero;
