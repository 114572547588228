import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

//? COMPONENTS
import App from './App';

//? STYLES
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ParallaxProvider>
				<App />
			</ParallaxProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
