import React, { useState, useEffect } from 'react';

import WOW from 'wowjs';
import 'animate.css';

//? STYLED COMPONENTS
import styled from 'styled-components';

//? ICONS
import { ReactComponent as ArrowLeft } from '../../images/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/svg/arrow-right.svg';

//? PARALLAX
import { Parallax } from 'react-scroll-parallax';

//? SWIPER
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

//? COMPONENTS
import WhyService from '../Service/Why';

const Section = styled.div`
	// height: 90vh;
	height: auto;
	background: rgb(60, 60, 59);
	background: linear-gradient(-180deg, rgba(60, 60, 59, 0.8547619731486344) 0%, rgba(60, 60, 59, 1) 65%);
`;

SwiperCore.use([Navigation, Autoplay, Pagination]);

const Why = () => {
	const [activeSlider, setActiveSlider] = useState(1);

	const setActiveSliderText = (index) => {
		setActiveSlider(index + 1);
	};

	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<>
			<Section className='w-full relative overflow-hidden'>
				<div className='absolute top-0 right-0 overflow-hidden h-full'>
					<Parallax className='parallax-why-image' y={[-30, 30]}>
						<img
							loading='lazy'
							src='/resources/img/tad-logo-grey-faded.png'
							alt='TAD right corner icon'
							className='opacity-25'
						/>
					</Parallax>
				</div>

				<div className='container py-10 md:py-20 md:px-24'>
					<h1 className='text-3xl md:text-5xl text-white font-inter font-medium pb-1 md:pb-2 wow animate__animated animate__fadeInUp'>
						Why
						<span className='text-white font-black font-montserrat mx-2'>
							tad<span className='text-tad-primary'>.</span>
						</span>
					</h1>
				</div>

				<div className='container md:px-24 overflow-x-visible'>
					<Swiper
						spaceBetween={20}
						breakpoints={{
							1024: {
								spaceBetween: 0,
							},
						}}
						loop={true}
						autoplay={{
							delay: 7000,
							disableOnInteraction: false,
						}}
						slidesPerView={'auto'}
						onSlideChange={(swiper) => setActiveSliderText(swiper.realIndex)}
						onSwiper={null}
						centeredSlides={true}
						navigation={{ prevEl: '.swiper-prev', nextEl: '.swiper-next' }}
					>
						<SwiperSlide>
							<img loading='lazy' src='/resources/img/tad-group-1.png' alt='group 1' className='w-full lg:px-10' />
						</SwiperSlide>
						<SwiperSlide>
							<img loading='lazy' src='/resources/img/tad-group-2.png' alt='group 2' className='w-full lg:px-10' />
						</SwiperSlide>
						<SwiperSlide>
							<img loading='lazy' src='/resources/img/tad-group-3.png' alt='group 3' className='w-full lg:px-10' />
						</SwiperSlide>
					</Swiper>

					<div className='flex justify-center'>
						<div className='flex justify-center items-center my-4 md:my-8'>
							<ArrowLeft className='text-gray-100 w-3 h-3 md:w-5 md:h-5 lg:w-7 lg:h-7 mx-0 md:mx-2 lg:mx-4 cursor-pointer swiper-prev' />
							<p className='paragraph-text text-center text-gray-100 mx-3 text-sm max-w-max'>0{activeSlider} - 03</p>
							<ArrowRight className='text-gray-100 w-3 h-3 md:w-5 md:h-5 lg:w-7 lg:h-7 mx-0 md:mx-2 lg:mx-4 cursor-pointer swiper-next' />
						</div>
					</div>
				</div>

				<div className='container mt-10 md:px-24 overflow-x-visible services-slider home'>
					<Swiper
						spaceBetween={30}
						loop={false}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						slidesPerView={'auto'}
						onSwiper={null}
						centeredSlides={false}
						pagination={{
							el: '.swiper-pagination',
							clickable: true,
							renderBullet: function (index, className) {
								return `<div class="bg-white h-2 w-10 md:h-3 md:w-14 rounded-lg mx-1 md:mx-2 ${className}"></div>`;
							},
						}}
					>
						<SwiperSlide>
							<WhyService
								icon={'/resources/img/design-icon.png'}
								title={'We design world-class products and brands'}
								description={`For many years we've helped top startups and enterprises turn ideas and goals into products and brands generating millions in revenue.`}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<WhyService
								icon={'/resources/img/dev-icon.png'}
								title={`Seamless design to development`}
								description={`Our experienced team and well-oiled process ensure our design are 100% for implementation with as title friction as possible.`}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<WhyService
								icon={'/resources/img/marketing-icon.png'}
								title={`Shaping trends in digital marketing`}
								description={`Leader in the marketing community, with 5 million+ views on social media, 100k+ followers, and millions of people impacted by our work.`}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<WhyService
								icon={'/resources/img/network-icon.png'}
								title={`Delivering high performing networks`}
								description={`Networking is our biggest passion. We use it to maintain a strong lead in nearly every networking hardware, software and security.`}
							/>
						</SwiperSlide>
					</Swiper>

					<div className='swiper-pagination flex justify-center items-center my-10 md:my-24 why-service-pagination'></div>
				</div>
			</Section>
		</>
	);
};

export default Why;
