import React from 'react';
import { NavLink } from 'react-router-dom';

//? ICONS
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg';

//? COMPONENTS
import SolidButton from '../Button/SolidButton/index';

const SideBar = ({ toggleSidebar }) => {
	return (
		<>
			<div className='sidebar fixed bg-tad-black w-full h-full md:w-1/2 flex flex-col inset-y-0 top-0 right-0 z-50 transform translate-x-full transition duration-500 ease-in-out shadow shadow-lg overflow-x-hidden'>
				<div className='w-full flex flex-row justify-end top-0 py-7 sm:py-10 px-7 md:px-16 z-10'>
					<NavLink to='/contact' onClick={toggleSidebar}>
						<SolidButton text={'contact us'} extraClass={'flex bg-tad-primary sidebar-contact text-white mr-5'} />
					</NavLink>
					<button
						className='flex justify-center items-center bg-white text-tad-black rounded-lg sidebar-close'
						onClick={toggleSidebar}
					>
						<CloseIcon className='close-menu-icon font-black' />
					</button>
				</div>

				<div className='w-full h-full flex flex-col items-center md:flex-row'>
					<div className='w-full my-auto flex flex-col justify-center items-center text-5xl text-gray-500 capitalize font-inter font-semibold'>
						<NavLink
							activeClassName='text-white'
							exact
							to='/'
							className='py-5 xl:py-8 transition duration-200 hover:text-tad-primary'
							onClick={toggleSidebar}
						>
							home
						</NavLink>
						<NavLink
							activeClassName='text-white'
							to='/portfolio'
							className='py-5 xl:py-8 transition duration-200 hover:text-tad-primary'
							onClick={toggleSidebar}
						>
							portfolio
						</NavLink>
						<NavLink
							activeClassName='text-white'
							to='/about'
							className='py-5 xl:py-8 transition duration-200 hover:text-tad-primary'
							onClick={toggleSidebar}
						>
							about us
						</NavLink>
					</div>
					<img
						loading='lazy'
						src='/resources/img/tad-logo-grey.png'
						alt='TAD menu icon'
						className='absolute pointer-events-none inset-y-0 inset-x-0 opacity-10 sm:opacity-25 m-auto w-full sm:w-5/6 transform scale-80 sm:scale-100 z-0'
					/>
				</div>

				<div className='w-full flex justify-center py-7 sm:py-10'>
					<div className='w-5/6 md:w-4/6 m-auto py-4 flex flex-row justify-around items-center text-white text-sm font-inter font-normal'>
						<p className='cursor-pointer hover:underline'>Dribbble</p>
						<p className='cursor-pointer hover:underline'>Instagram</p>
						<p className='cursor-pointer hover:underline'>Facebook</p>
						<p className='cursor-pointer hover:underline'>LinkedIn</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default SideBar;
